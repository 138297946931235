import React, { PropsWithChildren, useEffect } from 'react'
import { Calendly } from 'calendly'
import { Header } from './Header'
import { Footer } from './Footer'

export const PageLayout = ({ children }: PropsWithChildren) => {
  useEffect(() => {
    Calendly.setup()
  }, [])

  return (
    <>
      <Header />
      <main className="pb-20">{children}</main>
      <Footer />
    </>
  )
}
