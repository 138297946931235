import React from 'react'
import { GatsbyBrowser } from 'gatsby'
// import { initSentryGatsby } from 'sentry'
import { PageLayout } from './src/components/PageLayout'
import './src/styles/global.css'
import './src/styles/fonts.css'

// initSentryGatsby({
//   dsn: window?._env_?.SENTRY_DSN_LANDING,
//   tracesSampleRate: window?._env_?.SENTRY_TRACE_RATE,
//   environment: window?._env_?.ENVIRONMENT_NAME,
// })

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({ element, props }) => {
  return <PageLayout {...props}>{element}</PageLayout>
}
