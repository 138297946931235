export const EXTERNAL_URLS = {
  SIGN_UP: process.env.GATSBY_SIGN_UP_URL!,
  SELLER_PORTAL: process.env.GATSBY_SELLER_PORTAL_URL!,
}

export const INTERNAL_ROUTES = {
  HOME: '/',
  PRICING: '/pricing',
  CONTACT_US: '/contact-us',
}
